import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, vShow as _vShow, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "instructors" }
const _hoisted_2 = { class: "instructors__block" }
const _hoisted_3 = { class: "instructors__main-wrap" }
const _hoisted_4 = { class: "table__mobile-wrap" }
const _hoisted_5 = { class: "table__selects" }
const _hoisted_6 = { class: "mobile-menu-wrap" }
const _hoisted_7 = { class: "calendar__data-range-wrap" }
const _hoisted_8 = {
  key: 0,
  class: "calendar__custom-settings-wrap"
}
const _hoisted_9 = { class: "table__head" }
const _hoisted_10 = { class: "table__head-little-tr" }
const _hoisted_11 = { class: "table__sort-block" }
const _hoisted_12 = { class: "table__head-big-tr" }
const _hoisted_13 = { class: "table__sort-block" }
const _hoisted_14 = { class: "table__body-id" }
const _hoisted_15 = { class: "table__body-little-tr" }
const _hoisted_16 = { class: "table__body-big-tr" }
const _hoisted_17 = { class: "table__progress-bar-wrap" }
const _hoisted_18 = { class: "table__progress-bar-count" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminPanelNavMenu = _resolveComponent("AdminPanelNavMenu")!
  const _component_AnalyticsNavMenu = _resolveComponent("AnalyticsNavMenu")!
  const _component_AnaliticMenuSVG = _resolveComponent("AnaliticMenuSVG")!
  const _component_Select = _resolveComponent("Select")!
  const _component_SelectArrow = _resolveComponent("SelectArrow")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_SortArrow = _resolveComponent("SortArrow")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AdminPanelNavMenu)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_AnalyticsNavMenu, { class: "instructors__nav-menu desctop-menu" }),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_AnaliticMenuSVG, {
              class: "mobile-mene-svg",
              onClick: _ctx.toggleMenu
            }, null, 8, ["onClick"]),
            _createElementVNode("div", {
              class: _normalizeClass({
                'instructors__nav-menu': true,
                'mobile-menu': true,
                activeMobileMenu: _ctx.isMenuActive,
              })
            }, [
              _createVNode(_component_AnalyticsNavMenu)
            ], 2)
          ])), [
            [_directive_click_outside, _ctx.closeMenu]
          ]),
          _createVNode(_component_Select, {
            data: _ctx.dataAllInstructors,
            "select-name": "All Instructors",
            class: "instructors__select",
            title: 'full_name',
            onOptionSelected: _cache[0] || (_cache[0] = 
              (option) => _ctx.handleOptionSelected('instructor_id', option)
            )
          }, null, 8, ["data"]),
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", {
              class: "calendar__data-range",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleCustomPeriodsCalendar && _ctx.toggleCustomPeriodsCalendar(...args)))
            }, [
              _cache[11] || (_cache[11] = _createTextVNode(" Date range ")),
              _createVNode(_component_SelectArrow, {
                class: _normalizeClass({ isOpenSelectArrow: _ctx.isCustomPeriodsCalendarVisible })
              }, null, 8, ["class"])
            ]),
            (_ctx.isCustomPeriodsCalendarVisible)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("div", {
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectStaticRange(1)))
                  }, "Last 7 days"),
                  _createElementVNode("div", {
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectStaticRange(2)))
                  }, "Last 30 days"),
                  _createElementVNode("div", {
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectStaticRange(3)))
                  }, "All time"),
                  _createElementVNode("div", {
                    class: "calendar__custom-range",
                    onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.toggleCalendar && _ctx.toggleCalendar(...args)))
                  }, " Custom Range ")
                ]))
              : _createCommentVNode("", true),
            _withDirectives(_createVNode(_component_Calendar, {
              class: "calendar__calendar",
              "select-date-fill": this.selectDateFill
            }, null, 8, ["select-date-fill"]), [
              [_vShow, _ctx.isCalendarVisible]
            ])
          ])), [
            [_directive_click_outside, _ctx.closeCalendar]
          ]),
          _createElementVNode("div", {
            class: "instructors__reset-filters",
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.resetFilters && _ctx.resetFilters(...args)))
          }, " Reset filters ")
        ]),
        _cache[15] || (_cache[15] = _createElementVNode("div", { class: "table__title" }, "Instructors not tracking skills", -1)),
        _createElementVNode("div", _hoisted_9, [
          _cache[14] || (_cache[14] = _createElementVNode("div", { class: "table__head-id" }, "ID", -1)),
          _createElementVNode("div", _hoisted_10, [
            _cache[12] || (_cache[12] = _createTextVNode(" Instructor ")),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_SortArrow, {
                onClick: _cache[7] || (_cache[7] = ($event: any) => (this.getAnalytics('?search=', 'instructor')))
              }),
              _createVNode(_component_SortArrow, {
                onClick: _cache[8] || (_cache[8] = ($event: any) => (this.getAnalytics('?search=', '-instructor'))),
                class: "table__sort-bottom"
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _cache[13] || (_cache[13] = _createTextVNode(" Last noted school skills ")),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_SortArrow, {
                onClick: _cache[9] || (_cache[9] = ($event: any) => (this.getAnalytics('?search=', 'last_tracked_at')))
              }),
              _createVNode(_component_SortArrow, {
                onClick: _cache[10] || (_cache[10] = ($event: any) => (this.getAnalytics('?search=', '-last_tracked_at'))),
                class: "table__sort-bottom"
              })
            ])
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.analyticsData, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["table__body", { active: item.id === _ctx.activeItemId }]),
            key: item
          }, [
            _createElementVNode("div", _hoisted_14, _toDisplayString(item.id), 1),
            _createElementVNode("div", _hoisted_15, _toDisplayString(item.full_name), 1),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", {
                  class: "table__progress-bar",
                  style: _normalizeStyle({
                  width: item.students_that_moved_to_next_level * 10 + 'px',
                })
                }, null, 4),
                _createElementVNode("div", _hoisted_18, _toDisplayString(item.students_that_moved_to_next_level), 1)
              ])
            ])
          ], 2))
        }), 128)),
        _createVNode(_component_Pagination, {
          propertyMeta: _ctx.instructorsMeta,
          propertyList: _ctx.instructorsData,
          page: _ctx.page,
          handlePage: _ctx.handlePage
        }, null, 8, ["propertyMeta", "propertyList", "page", "handlePage"])
      ])
    ])
  ]))
}