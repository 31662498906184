
import { defineComponent } from "vue";
import AdminPanelNavMenu from "@/components/molecules/AdminPanelNavMenu.vue";
import SortArrow from "@/assets/svg/sort-arrow.svg?inline";
import AnaliticMenuSVG from "@/assets/svg/analitic-menu.svg?inline";
import SelectArrow from "@/assets/svg/select-arrow.svg?inline";
import AlertBlock from "@/components/molecules/AlertBlock.vue";
import Pagination from "@/components/molecules/Pagination.vue";
import AnalyticsNavMenu from "@/components/molecules/AnalyticsNavMenu.vue";
import Select from "@/components/atoms/CustomSelect.vue";
import Calendar from "@/components/molecules/Calendar.vue";
import vClickOutside from "click-outside-vue3";

export default defineComponent({
  name: "NotTrackingSkills",
  data() {
    return {
      page: 1,
      instructorsData: [
        {
          countProgres: 10,
        },
        {
          countProgres: 60,
        },
      ],
      instructorsMeta: null,
      errorInstructors: null,
      sortData: "",
      idInstructor: null,
      dataAllInstructors: [],
      dataSkills: [],
      selectedInstructor: false,
      isCalendarVisible: false,
      analyticsData: {},
      analyticsMeta: null,
      erroranAlytics: null,
      filters: "",
      filtersDate: "",
      instructorNameSelected: "",
      instructorId: "",
      activeItemId: null,
      isMenuActive: false,
      isCustomPeriodsCalendarVisible: false,
      period: 1,
    };
  },
  components: {
    AdminPanelNavMenu,
    AnalyticsNavMenu,
    AnaliticMenuSVG,
    SortArrow,
    AlertBlock,
    Pagination,
    Select,
    Calendar,
    SelectArrow,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mounted() {
    this.getAnalytics();
    this.getInstructors();
  },
  methods: {
    getAnalytics(params, sort) {
      let searchParam = `?page=${this.page}&n=20&search=`;
      let startDate = "";
      let endDate = "";

      const currentDate = new Date();
      const pastDate = new Date();
      if (this.period === 1) {
        pastDate.setDate(currentDate.getDate() - 7);
      } else if (this.period === 2) {
        pastDate.setDate(currentDate.getDate() - 30);
      } else if (this.period === 3) {
        pastDate.setDate(currentDate.getDate() - 1000);
      }

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      if (sort === undefined) {
        sort = "";
      }

      startDate =
        this.selectedStartDate !== undefined
          ? this.selectedStartDate
          : formatDate(pastDate);
      endDate =
        this.selectedEndDate !== undefined
          ? this.selectedEndDate
          : formatDate(currentDate);

      params =
        searchParam +
        "&n=20&sort=" +
        sort +
        this.filters +
        this.filtersDate +
        `&date_from=${startDate}` +
        `&date_to=${endDate}`;

      this.$store
        .dispatch("analytics/instructorsNotTrackingSkills", params)
        .then(
          (res) => {
            this.analyticsData = res.data;
            this.analyticsMeta = res.meta;
          },
          (error) => {
            this.erroranalytics = error.response.data.errors;
          }
        );
    },
    getInstructors() {
      const params = "?n=100";
      this.$store.dispatch("instructors/getInstructors", params).then(
        (res) => {
          this.dataAllInstructors = res.data;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    handlePage(page) {
      this.page = page;
      this.getInstructors();
    },
    handleOptionSelected(filterKey, selectedOption, callback) {
      const newFilter = `filter[${filterKey}]=${selectedOption.id}`;
      const regex = new RegExp(`filter\\[${filterKey}\\]=[^&]*`);
      if (selectedOption.id !== undefined) {
        if (regex.test(this.filters)) {
          this.filters = this.filters.replace(regex, newFilter);
        } else {
          this.filters += `&${this.filters}&${newFilter}`;
        }
      } else
        this.filters = this.filters
          .replace(regex, "")
          .replace(/&{2,}/g, "&")
          .replace(/[?&]$/, "");
      if (callback) callback(selectedOption.id);
      this.getAnalytics();
    },
    toggleCalendar() {
      this.isCalendarVisible = !this.isCalendarVisible;
    },
    closeCalendar() {
      this.isCustomPeriodsCalendarVisible = false;
      this.isCalendarVisible = false;
    },
    selectDateFill(date, month, year) {
      const selectedDate = new Date(year, month, date.value);
      const currentDate = new Date();
      if (selectedDate > currentDate) {
        return;
      }
      if (date.value !== "") {
        const selectedDate = new Date(year, month, date.value);
        if (!this.selectedStartDate) {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
        } else if (
          !this.selectedEndDate &&
          selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate() >=
            this.selectedStartDate
        ) {
          this.selectedEndDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
        } else {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
          this.selectedEndDate = null;
        }
      }
      if (this.selectedEndDate) {
        this.filtersDate = `&date_from=${this.selectedStartDate}&date_to=${this.selectedEndDate}`;
        this.getAnalytics();
      }
    },
    formatDate(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      const day = date.getDate();
      const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
        date
      );
      const year = date.getFullYear();
      return `${day} ${month} ${year}`;
    },
    resetFilters() {
      window.location.reload();
    },
    clearSelection() {
      if (this.$refs.customSelectRefLevel) {
        this.$refs.customSelectRefLevel.clearSelected();
      }
      if (this.$refs.customSelectRefSkills) {
        this.$refs.customSelectRefSkills.clearSelected();
      }
    },
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    },
    closeMenu() {
      this.isMenuActive = false;
    },
    toggleCustomPeriodsCalendar() {
      this.isCustomPeriodsCalendarVisible =
        !this.isCustomPeriodsCalendarVisible;
    },
    selectStaticRange(period) {
      this.period = period;
      this.isCustomPeriodsCalendarVisible = false;
      this.isCalendarVisible = false;
      this.getAnalytics();
    },
  },
});
